@import '../../scss/base.module';

.modalOptIn {
  max-width: 327px;
  height: 481px;
  display: flex;
  flex-direction: column;

  &__header {
    padding-bottom: 0!important // TO-DO: Temporal fix to avoid override due to import order;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__image {
      display: flex;
      justify-content: center;
    }

    &__text {
      @include font-m;

      display: flex;
      color: $interaction-dark;
    }

    &__checkbox {
      @include font-bold;
      @include font-s;

      color: $interaction-dark;
    }

    &__buttonContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: $spacing-s;

      &-button {
        @include font-bold;

        width: 100%;
        color: white;
      }
    }
  }
}
