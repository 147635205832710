.modalBody {
  flex-grow: 1;
  padding: 20px;

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
